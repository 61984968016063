import React from "react";
import styled from "@emotion/styled";
import { RowWithCopyAndImageV2 } from "./RowWithCopyAndImageV2";
import { Section as SectionBase } from "../../Section";

const Section = styled(SectionBase)`
  padding-top: 0;
`;

export const RowsOfAlternatingCopyAndImageV2 = ({ data, children }: any) => {
  return (
    <Section>
      <SectionBase.Heading title={data.title} subtitle={data.subheading} />
      {children}
    </Section>
  );
};

RowsOfAlternatingCopyAndImageV2.Row = RowWithCopyAndImageV2;
