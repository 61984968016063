import { css } from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";
import { Button as ButtonBase } from "../../Button";
import { getResizedImageURL } from "../../utils";
import { isZeroPage } from "../../utils/is-zero";
import { isZeroRoadmap } from "../../utils/is-zero-roadmap";
import { Wrapper } from "../../Wrapper";

import styles from "../styles/RowWithCopyAndImage.module.scss";

const Button = styled(ButtonBase)`
  margin-top: 20px;
`;

export type RowWithCopyAndImageProps = {
  bodyCopy: string;
  buttonLink?: string;
  buttonText?: string;
  compact: boolean;
  idx: number;
  images: any[];
  text: string;
  title: string;
  slug?: string | string[];
};

const ZERO_PAGES_IDS = {
  ZERO: "zero",
  ROADMAP: "ecologi-zero-roadmap",
  PATHWAY: "the-pathway-to-net-zero",
};

const zeroVariants = {
  [ZERO_PAGES_IDS.ZERO]: styles.zero,
  [ZERO_PAGES_IDS.ROADMAP]: styles.roadmapBackground,
  [ZERO_PAGES_IDS.PATHWAY]: styles.pathway,
  ROADMAP_ODD: `${styles.roadmap}  ${styles.roadmapBackground}`,
};

const wrapperVariants = {
  [ZERO_PAGES_IDS.PATHWAY]: css`
    padding-top: 32px;
    padding-bottom: 32px;
  `,
};

const RowWrapper = styled(Wrapper)<{ $slug?: string | string[] }>`
  ${(props) => wrapperVariants[props?.$slug as string]};
`;

export const RowWithCopyAndImage = (props: RowWithCopyAndImageProps) => {
  const { title, images, bodyCopy, idx, slug } = props;

  const image = images.length > 0 ? images[0] : null;
  let imageUrl = image ? image.url : "";
  imageUrl = getResizedImageURL(imageUrl, 1000, 640, { crop: true });

  const wrapperProps = props.compact ? {} : { isLarge: true };

  const isZeroRoadmapPage = isZeroRoadmap(slug);

  const rowCls = classNames([
    styles.row,
    idx % 2 === 0 && !isZeroRoadmapPage ? styles.rowEven : null,
    zeroVariants[
      isZeroRoadmapPage && idx % 2 === 1 ? "ROADMAP_ODD" : (slug as string)
    ],
  ]);

  return (
    <section className={rowCls}>
      <RowWrapper {...wrapperProps} $slug={slug}>
        <div className={styles.content}>
          {image && (
            <div className={styles.imageWrapper}>
              <img
                className={styles.image}
                alt={image.alt}
                src={imageUrl}
                loading="lazy"
              />
            </div>
          )}
          <div className={styles.text}>
            <h5 className={styles.heading}>{title}</h5>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{ __html: bodyCopy }}
            />
            {props.buttonText && props.buttonLink && (
              <Button
                text={props.buttonText}
                href={props.buttonLink}
                green2
                darkBlue={isZeroPage(slug)}
              />
            )}
          </div>
        </div>
      </RowWrapper>
    </section>
  );
};
