import React from "react";
import styled from "@emotion/styled";

import { getResizedImageURL } from "../../utils";
import { Button as ButtonBase } from "../../Button";
import { css } from "@emotion/react";

const Row = styled.section<{ compact: boolean; isEven: boolean }>`
  background: ${(props) => props.theme.colors.snowdrop};
  padding: 17px 0px;

  ${(props) =>
    props.isEven &&
    css`
      @media ${props.theme.bp.tabletUp} {
        .content {
          flex-direction: row-reverse;
        }
        .text {
          padding-left: 0;
          padding-right: 50px;
        }
      }
    `}
`;

const Content = styled.div`
  @media ${(props) => props.theme.bp.tabletUp} {
    display: flex;
  }

  &:nth-of-type(even) {
    @media ${(props) => props.theme.bp.tabletUp} {
      flex-direction: row-reverse;
    }
  }
`;

const ImageWrapper = styled.div`
  @media ${(props) => props.theme.bp.tabletUp} {
    flex: 1 1 50%;
  }
`;

const Image = styled.img`
  display: block;
  margin-bottom: 20px;
  width: 100%;
`;

const Text = styled.div`
  color: ${(props) => props.theme.colors.charcoal};
  font-size: 18px;
  line-height: 1.6;

  @media ${(props) => props.theme.bp.tabletUp} {
    flex: 1 1 50%;
    padding-left: 50px;
  }
`;

const Heading = styled.h5<{ isCompact: boolean }>`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 10px;

  @media ${(props) => props.theme.bp.tabletUp} {
    margin-bottom: 20px;
    font-size: 24px;
  }
`;

const Body = styled.div`
  color: ${(props) => props.theme.colors.charcoal};
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;

  p {
    margin-bottom: 20px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  a {
    color: ${(props) => props.theme.colors.jungle};

    &:hover {
      opacity: 0.7;
    }
  }
`;

const Button = styled(ButtonBase)`
  margin-top: 20px;
`;

export type RowWithCopyAndImageProps = {
  title: string;
  text: string;
  images: any[];
  bodyCopy: string;
  compact: boolean;
  buttonText?: string;
  buttonLink?: string;
  idx: number;
};

export const RowWithCopyAndImageV2 = (props: RowWithCopyAndImageProps) => {
  const { title, images, bodyCopy, compact, idx } = props;

  const image = images.length > 0 ? images[0] : null;
  let imageUrl = image ? image.url : "";
  imageUrl = getResizedImageURL(imageUrl, 1000, 640, { crop: true });

  return (
    <Row compact={compact} isEven={idx % 2 === 0}>
      <Content className="content">
        {image && (
          <ImageWrapper>
            <Image alt={image.alt} src={imageUrl} loading="lazy" />
          </ImageWrapper>
        )}
        <Text className="text">
          <Heading isCompact={compact}>{title}</Heading>
          <Body dangerouslySetInnerHTML={{ __html: bodyCopy }} />
          {props.buttonText && props.buttonLink && (
            <Button text={props.buttonText} href={props.buttonLink} green2 />
          )}
        </Text>
      </Content>
    </Row>
  );
};
