import React from "react";
import { RowWithCopyAndImage } from "./RowWithCopyAndImage";
import styles from "../styles/RowsOfAlternatingCopyAndImage.module.scss";
import { slugify } from "../../utils/slugify";
import styled from "@emotion/styled";
import { Button } from "../../Button";

const ZERO_PAGES_IDS = {
  ZERO: "zero",
  ROADMAP: "ecologi-zero-roadmap",
  PATHWAY: "the-pathway-to-net-zero",
};

const zeroMapper = {
  [ZERO_PAGES_IDS.ZERO]: styles.zeroWrapper,
  [ZERO_PAGES_IDS.ROADMAP]: styles.roadmapWrapper,
  [ZERO_PAGES_IDS.PATHWAY]: styles.pathwayWrapper,
};

export const RowsOfAlternatingCopyAndImage = ({
  data,
  children,
  slug,
}: any) => {
  const stylesWrapper = zeroMapper[slug];

  return (
    <>
      <section
        className={`${
          stylesWrapper ? stylesWrapper : styles.wrapper
        } rows-of-alternating-copy-and-image`}
        id={slugify(data.title)}
      >
        <h3 className={styles.heading}>{data.title}</h3>
        <h4 className={styles.subheading}>{data.subheading}</h4>
      </section>
      {children}
      {data.button_text && data.button_url && (
        <ButtonWrapper>
          <Button text={data.button_text} href={data.button_url} darkBlue />
        </ButtonWrapper>
      )}
    </>
  );
};

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 152px;
  width: fit-content;
`;

RowsOfAlternatingCopyAndImage.Row = RowWithCopyAndImage;
