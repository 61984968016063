import { RowsOfAlternatingCopyAndImage } from "@ecologi/react-components/src/RowsOfAlternatingCopyAndImage";
import { RowsOfAlternatingCopyAndImageV2 } from "@ecologi/react-components/src/RowsOfAlternatingCopyAndImageV2";
import { stringReplaceVariables, weeklyCostB2c } from "@ecologi/shared";
import { useRouter } from "next/router";
import { useStore } from "../../store";

function Row(props) {
  const { location } = useStore();
  const { text, compact } = props;

  const localWeeklyCostB2c = location
    ? weeklyCostB2c[location.countryCode]
    : weeklyCostB2c.US;

  const bodyCopy = text.replace(
    stringReplaceVariables.localWeeklyCostB2c,
    localWeeklyCostB2c
  );

  return compact ? (
    <RowsOfAlternatingCopyAndImageV2.Row {...props} bodyCopy={bodyCopy} />
  ) : (
    <RowsOfAlternatingCopyAndImage.Row {...props} bodyCopy={bodyCopy} />
  );
}

export default function ImageRows(props) {
  const Container = props.data.compact
    ? RowsOfAlternatingCopyAndImageV2
    : RowsOfAlternatingCopyAndImage;

  const router = useRouter();
  return (
    <Container {...props} slug={router?.query?.slug}>
      {props.data.rows.map((row, idx: number) => (
        <Row
          {...row}
          key={idx}
          idx={idx}
          compact={props.data.compact}
          buttonText={row.button_text}
          buttonLink={row.button_link}
          slug={router?.query?.slug}
        />
      ))}
    </Container>
  );
}
